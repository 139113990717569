.spinner-wrapper {
    background: url('../../assets/logo.jpg') no-repeat center;
    background-size: cover;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-footer {
    position: absolute;
    bottom: 0;
    left: 10px;
    color: #2B53A0;
    font-family: 'daray', serif;
    font-size: 1.5rem;
}
