.user-page-container {
    height: 70vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.rowed {
    display: flex;
    flex-direction: row !important;
    gap: 2rem;
}


.user-page-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: black;
    border: 2px solid lightgray;
    border-radius: 1rem;
    background: #E4E4E461;
    margin-bottom: 3rem;
}

.user-page-main ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.user-page-main ul li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
    color: #333;
    font-size: 1rem;
}

.user-page-main ul li:last-child {
    border-bottom: none;
}

.proposed-problems-container {
    position: fixed;
    right: 2rem;
    bottom: 5rem;
}

.proposed-problems-icon-wrapper {
    position: relative;
    display: inline-block;
}

.proposed-problems-count {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold
}

.user-problems-header {
    font-family: Inter;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 24.2px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}