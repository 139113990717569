.reviews-page-container {
    display: flex;
    flex-direction: column;
}

.reviews-page-content {
    color: black;
}

.reviews-page-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reviews-page-menu-button {
    color: #484848;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.reviews-page-menu-button:hover {
    text-decoration: underline;
}

.reviews-page-menu-button.active {
    border-bottom: 2px solid #2B53A0;
    font-weight: bold;
}

.reviews-page-content {
    display: block;
    overflow-y: auto;
    height: calc(100vh - 2rem);
    max-height: 100%;
    box-sizing: border-box;
}