.problem-page-header {
    display: flex;
    text-align: left;
    margin-bottom: 30px;
    color: black;
    flex-direction: row;
    font-size: 1.5rem;
    justify-content: space-between;
}

.problem-page-header p {
    font-family: Inter;
    font-size: 40px;
    font-weight: 500;
    line-height: 48.41px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.review-link {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 1.1rem;
    color: #2B53A0;
    text-decoration: none;
    transition: color 0.3s ease;
    text-align: center;
}

.review-link:hover {
    color: #25488c;
    text-decoration: underline;
}

.error-message {
    color: #ff4d4f;
    font-size: 0.9rem;
    margin-top: 5px;
}

.general-error-message {
    color: #ff4d4f;
    background: #fff3f3;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
}

.review-buttons {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
}

.review-buttons button {
    padding: 6px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    outline: none;
}

.review-details {
    color: black;
}

.review-details input, .review-details select, .review-details textarea {
    background: #D0D0D0;
    border-radius: 1rem;
    width: 100%;
    padding: 20px;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    color: black;
}