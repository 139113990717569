.over-hidden {
    overflow: hidden;
}

.filters-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: 100%;
}

.filter-group {
    margin-top: 1rem;
    width: 100%;
    background-color: #E4E4E4;
    padding: 15px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 1rem;
}

.filter-group label,
.checkbox-group label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #333;
    text-align: center;
    cursor: pointer;
    font-family: Inter;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0.01em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.problem-review-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    margin: 5rem auto 0 auto;
}

.problem-review-modal-container {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin-top: 1rem;
}

.problem-review-modal-container textarea {
    background: #D0D0D0;
    border-radius: 1rem;
    border: none;
    font-family: Inter;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 24.2px;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.problem-review-modal-container input,
.problem-review-modal-container select {
    border: none;
    background: #D0D0D0;
    border-radius: 1rem;
    width: 100%;
    padding: 20px;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    color: black;
}

.problem-review-modal-container p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.problem-review-header-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: black;
    background: transparent;
    border: none;
    cursor: pointer;
}

.icon-button img,
.icon-button svg {
    width: 100%;
    height: 100%;
}
