.problems-list-table {
    font-size: 1rem !important;
}

.problems-list-table tbody{
    height: 55vh !important;
}

@media (max-width: 768px) {
    .problems-list-table tbody {
        height: 45vh !important;
    }
}
