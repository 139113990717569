.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background-color: #2B53A0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 1rem;
    z-index: 1000;
    box-sizing: border-box;
}

.header-title {
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
}

.header img {
    margin-right: 0.5rem;
    height: 2rem;
    object-fit: contain;
}

.header svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 0.1rem;
}

.header p.problem-name {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.nav-text {
    width: 100%;
    color: black;
    height: 100%;
}

.nav-text.active {
    width: 100%;
    color: white;
    background: #2B53A0;
}

.dropdown-menu {
    position: absolute;
    top: 7rem;
    left: 0;
    background-color: #D9D9D9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    min-width: 200px;
}

@media (max-width: 768px) {
    .header {
        height: 8rem;
    }

    .dropdown-menu {
        top: 8rem;
    }
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 3rem;
    transition: background-color 0.2s;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.dropdown-menu li a {
    padding-left: 1.5rem;
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;
}
