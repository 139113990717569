.reviewers-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    overflow: hidden;
}

.reviewers-modal {
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    color: black;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
}

.reviewers-modal-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.reviewers-modal-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.reviewers-modal-body {
    padding: 16px;
    overflow-y: auto;
    max-height: 60vh;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.reviewers-modal-body table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.reviewers-modal-body td {
    padding: 8px;
    text-align: left;
    background: #D9D9D9;
    border-bottom: 1px solid #ddd;
}


.reviewers-modal-body td:nth-child(2n-1) {
    border-bottom-left-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
}

.reviewers-modal-body td:nth-child(2n) {
    border-bottom-right-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}

.reviewers-input-search {
    margin: 10px 0;
    text-align: center;
}

.search-input {
    width: auto;
    max-width: 300px;
    padding: 8px;
    font-size: 13px;
    border: 1px solid #ccc;
    border-radius: 1rem;
    outline: none;
    height: 0.6rem;
    background: #D9D9D9;
}

.search-input:focus {
    border-color: #03a696;
}