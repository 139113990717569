.history-review-page {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.01em;
    text-align: left;
    margin-top: 1rem;
    overflow-y: auto;
    height: 60vh;
}

.history-review-page ul {
    list-style-type: none;
    padding: 0;
    margin-top: 1rem;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
}

.history-review-page li {
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
    background-color: #D9D9D9;;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.2s ease;
    margin: 5px 0;
}

.history-review-page li:last-child {
    border-bottom: none;
}

.history-review-page li:hover {
    background-color: #2B53A0;
}

.history-review-page li span {
    font-weight: bold;
    color: #2B53A0;
}

.history-review-page a {
    text-decoration: none;
}

.history-review-page p {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin: 20px 0;
    background-color: #fdfdfd;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
