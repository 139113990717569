.problem-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
}

.problem-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.withdraw-button {
    background-color: #FF3B30;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
}

.withdraw-button:hover {
    background-color: #D32F2F;
}

.problem-details {
    overflow-y: auto;
    height: 67vh;
}

.problem-page-name {
    width: auto;
    max-width: 50%;
    position: relative;
    font-size: 40px;
    font-weight: 500;
    line-height: 48.41px;
    letter-spacing: 0.01em;
    color: black;
}

.problem-page-name .beauty {
    font-size: 15px;
    position: absolute;
    top: -10px;
    right: -30px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #2B53A0;
}

.problem-page-name .complexity {
    font-size: 15px;
    position: absolute;
    bottom: -10px;
    right: -30px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #FF4500;
}
