.home-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
}

.home-page-header h2 {
    color: black;
    font-family: Inter, sans-serif;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
}

.add-problem-button {
    font-family: Inter, sans-serif;
    font-weight: 400;
    background-color: #2B53A0;
    color: var(--Grays-white);
    border: none;
    padding: 10px 20px;
    border-radius: 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-problem-button:hover {
    background-color: #26498c;
}

.empty-problem-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
}

.empty-problem-list p {
    color: black;
    text-align: center;
    font-size: 1.5rem;
}

.empty-problem-list img {
    margin: 0 auto;
}

