.add-review-form {
    display: flex;
    flex-direction: column;
}

.beauty-comment {
    margin-top: 5px;
    font-style: italic;
    color: #555;
}

.error {
    color: red;
    font-size: 0.9em;
}


.review-modal-select-container {
    display: flex;
    margin-top: 0;
    flex-direction: row;
}

.review-modal-select-container > * {
    flex: 1;
}

.problem-review-buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.problem-review-buttons button {
    padding: 6px 20px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    outline: none;
}

.problem-review-buttons-defer {
    background-color: #03A696;
    color: white;
}

.problem-review-buttons-defer:hover {
    background-color: #028b78;
}

.problem-review-buttons-submit {
    background-color: #2B53A0;
    color: white;
}

.problem-review-buttons-submit:hover {
    background-color: #234b81;
}

.problem-review-buttons-close {
    background-color: #FF5050;
    color: white;
}

.problem-review-buttons-close:hover {
    background-color: #e04e4e;
}

.problem-review-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.tags-container {
    margin-top: 1rem;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0 1rem;
}

.tag-item {
    display: flex;
    align-items: center;
    background-color: #D0D0D0;
    border-radius: 16px;
    padding: 0.3rem 0.8rem;
    font-size: 0.9rem;
    color: #333;
}

.tag-text {
    margin-right: 0.5rem;
}

.remove-tag-button {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #888;
    cursor: pointer;
}

.remove-tag-button:hover {
    color: #d9534f;
}

.remove-tag-button:focus {
    outline: none;
}
