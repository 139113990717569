.problems-page {
    overflow: hidden;
}

.filters-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 1rem 0;
}

.problem-search-filters {
    font-size: 1rem;
    gap: 1rem;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 2.5rem;
}

.problem-search-filters label {
    color: black;
}

.complexity-filter-container {
    border-radius: 1rem;
    background: #D9D9D9;
    display: flex;
    flex-direction: row;
    align-self: center;
    height: 1.9rem;
    width: 100%;
    justify-content: space-between;
}

.complexity-filter-container input {
    text-align: center;
    border: none;
    background: #D9D9D9;
    width: 40px;
    font-size: 16px;
    border-radius: 1rem;
}

.problem-search-filters-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.problem-search-filters select {
    font-size: 16px;
    height: 1.9rem;
    border: none;
    width: 40%;
    padding: 6px 10px;
    border-radius: 1rem;
    background-color: #D9D9D9;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s;
}

.problem-search-input {
    width: 100%;
    padding: 0;
    height: 1.9rem;
    border: 1px solid #ccc;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #D9D9D9;
    font-size: 1rem;
    font-family: Inter, serif;
    font-style: italic;
    font-weight: 400;
    text-align: left;
}

.problem-search-input input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    background: transparent;
    font-size: 1rem;
    font-family: Inter, serif;
    font-style: italic;
    font-weight: 400;
    color: #333;
}

.problems-table {
    margin-top: 1rem;
    width: 100%;
    border-collapse: collapse;
    background-color: #D9D9D9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    color: black;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    display: table;
}

.problems-table thead {
    display: table;
    width: 100%;
}

.problems-table tbody {
    display: block;
    overflow-y: auto;
    width: 100%;
    height: 54vh;
}

@media (max-width: 768px) {
    .problems-table tbody {
        height: 46vh;
    }
}

.problems-table tbody tr {
    height: 4rem;
    display: table;
    width: 100%;
    table-layout: fixed;
}

.problems-table th,
.problems-table td {
    text-align: center;
    word-wrap: break-word;
    min-height: 2.5rem;
    border-bottom: 1px solid black;
}

.problems-table th {
    background-color: #D9D9D9;
    color: black;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 20%;
}

.problems-table td {
    background-color: #D9D9D9;
    color: black;
}

.problems-table .sortable {
    cursor: pointer;
}


.problems-table .sortable {
    cursor: pointer;
    position: relative;
}

.problems-table .sortable:hover {
    background-color: #f1f1f1;
}


.problems-table .download-cell button:hover {
    color: #0056b3;
}


