.problem-review-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.problem-review-modal-content {
    width: 90%;
    max-width: 1000px;
    height: 90%;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Arial, sans-serif;
}

.problem-review-modal-content h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #333;
}

.problem-review-modal-content p {
    font-size: 1rem;
    color: #555;
    margin: 1rem 0;
}

.downloadSolutionButton {
    background: #2B53A0;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 1rem;
    border-radius: 1rem;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.downloadSolutionButton:hover {
    color: #1d3667;
}

.problem-modal-close-button {
    padding: 0.5rem 1rem;
    background-color: red;
    color: white;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 1rem;
    align-self: center;
    transition: background-color 0.3s;
}

.problem-modal-close-button:hover {
    background-color: darkred;
}

.problem-modal-buttons {
    margin: 1rem auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
