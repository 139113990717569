.reviews-list {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #fff;
    border-top: 1px solid #ddd;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
}

.reviews-list.hidden {
    transform: translateY(100%);
}

.review {
    margin: 1rem;
    background-color: #D0D0D0;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 8px;
    transition: transform 0.2s ease;
}

.review p {
    margin: 4px 0;
    line-height: 1.6;
}

.review-latex {
    font-style: italic;
    color: #555;
    margin-bottom: 12px;
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555;
    font-size: 0.85rem;
    margin-bottom: 8px;
}

.review-header .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.review-header .user-info .avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}

.review-header .user-info .user-name {
    font-weight: bold;
    color: #2B53A0;
}

.review-header .user-info .review-time {
    color: #888;
    font-size: 0.8rem;
}

.review-header .icons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.review-footer {
    font-size: 0.85rem;
    color: #777;
    font-weight: 500;
    text-align: right;
}

.review-header .icons svg {
    color: #2B53A0;
    font-size: 1.2rem;
}

.review-header .icons svg:hover {
    color: #2B53A0;
}

.show-problem-reviews-button {
    display: block;
    margin: 16px auto;
    padding: 8px 16px;
    background-color: #2B53A0;
    color: #fff;
    border: none;
    border-radius: 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.show-problem-reviews-button:hover {
    background-color: #213d75;
}

.well-known {
    color: #888;
    font-size: 0.85rem;
}

.review .review-latex {
    color: #333;
}

.review .review-header .icons p {
    margin: 0;
    color: #2B53A0;
}

.review .review-header .icons svg:hover {
    color: #1c1c1c;
}
