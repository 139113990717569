.authors-list {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.authors-filters {
    text-align: center;
    margin-bottom: 20px;
}

.users-sort-select {
    height: 2rem;
    width: 100%;
    border-radius: 1rem;
    background-color: #D9D9D9;
    cursor: pointer;
    outline: none;
    font-size: 1rem;
    transition: border-color 0.3s;
    border: 1px solid #ccc;
}

.authors-search-bar {
    margin-bottom: 20px;
    display: flex;
    text-align: center;
}

.authors-search-bar input {
    width: 100%;
    height: 2rem;
    border: 1px solid #ccc;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #D9D9D9;
    font-size: 1rem;
}

.no-users-message {
    text-align: center;
    color: #999;
    font-size: 1.2rem;
}
