.register-page {
    padding: 4rem 1rem;
    overflow-y: auto;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-group {
    margin-bottom: 1rem;
}

.register-page input,
.register-page select {
    border: none;
    background: #D0D0D0;
    border-radius: 1rem;
    width: 100%;
    padding: 20px;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    color: black;
}

.register-page-header {
    color: black;
    font-family: Inter, sans-serif;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-align: left;
}

.accept-terms-container {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    color: black;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}