.proposed-problems-list-overlay {
    position: fixed;
    top: 0;
    color: black;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.proposed-problems-page-header {
    display: flex;
    color: black;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.proposed-problems-list-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    flex-direction: column;
}

.proposed-problems-table {
    margin-top: 1rem;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    background-color: #D9D9D9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: black;
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    overflow-y: auto;
    max-height: calc(80vh - 150px);
}

.proposed-problems-table tr {
    text-align: center;
    border-bottom: 2px solid lightgrey;
}

.solve-proposed-problem-button {
    font-weight: bold;
    font-size: 1rem;
}
