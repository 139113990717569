.desktop-content {
    display: flex;
    flex-direction: column !important;
}

.smart-woman-image {
    margin: auto 0 auto 4rem;
    width: 40%;
}

.add-problem-modal-body {
    display: flex;
    flex-direction: row;
}

.modal-container {
    overflow-x: hidden;
    background-color: white;
    width: 100%;
    z-index: 1000;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.modal-header {
    margin-top: 6rem;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    letter-spacing: 0.03em;
    text-align: left;
    background: #F4F4F4;
    color: black;
}

.modal-header p {
    margin: 0;
}

.close-modal-button {
    border: none;
    border-radius: 0.8rem;
    color: white;
    background: var(--red-color);
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.close-modal-button:hover {
    background: #c61515;
}

.add-problem-modal-content {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
}

.add-problem-modal-container {
    width: 90%;
    margin: 0 auto 15px;
}

.add-problem-modal-container input,
.add-problem-modal-container select,
.add-problem-modal-container textarea {
    border: none;
    background: #D0D0D0;
    border-radius: 1rem;
    width: 100%;
    padding: 20px;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    color: black;
}

::placeholder {
    color: black;
}

.textarea-container {
    position: relative;
}

textarea {
    width: 100%;
    height: 150px;
    padding: 1rem;
    box-sizing: border-box;
    font-size: 1rem;
    resize: none;
}

.textarea-label {
    position: absolute;
    top: 1rem;
    left: 1rem;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    color: black;
}

.hidden {
    display: none;
}

.add-problem-submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #03A696;
    width: 50%;
    margin: 3rem auto;
    height: 2.5rem;
    border: none;
    border-radius: 1rem;
    color: white;
    font-weight: 600;
}

.add-problem-submit-button:hover {
    background: #039385;
    color: whitesmoke;
}

.add-problem-modal-general-error {
    margin: 0 auto;
    width: 80%;
    font-size: 1rem;
}

.modal-latex-viewer {
    color: black;
    background: #D0D0D0;
    padding: 0.5rem 20px;
    margin: 0 auto;
    border-radius: 2rem;
}

input[type="file"] {
    display: none;
}

.problem-file-label {
    color: black;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-sizing: border-box;
    border: none;
    border-radius: 2rem;
    background: #D0D0D0;
    display: flex;
    padding: 20px;
    cursor: pointer;
    flex-direction: column;
}

.confirmation-popup {
    position: fixed;
    top: 50%;
    justify-items: center;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 60%;
}

.confirmation-popup-header {
    font-family: Roboto, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    color: black;
    letter-spacing: 0.03em;
}

.confirmation-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.confirmation-buttons button {
    padding: 8px 16px;
    border: none;
    color: white;
    border-radius: 2rem;
    cursor: pointer;
    width: 4rem;
}

.confirmation-buttons button:hover {
    background-color: #f0f0f0;
}

.blur {
    overflow: hidden;
    filter: blur(5px);
    pointer-events: none;
}

.confirmation-popup-confirm-button {
    margin-left: 2rem;
    background: #03A696;
}

.confirmation-popup-cancel-button {
    margin-right: 2rem;
    background: #FF5050;
}