.admin-users-search-bar {
    display: flex;
}

.admin-users-search-bar input {
    text-align: center;
    margin: auto;
    border: none;
    background: #E8E7E7;
    width: 100%;
    height: 1.7rem;
    border-radius: 1rem;
    font-size: 20px;
}

.admin-users-table tbody {
    height: 65vh !important;
}

@media (max-width: 768px) {
    .admin-users-table tbody {
        height: 55vh !important;
    }
}

.admin-users-table tr {
    background: #D9D9D9;
    border-radius: 1rem;
}

.admin-users-table th, .admin-users-table td {
    padding: 10px;
    text-align: left;
    font-size: 1rem;
}

.admin-users-table select {
    background: #2B53A0;
    border-radius: 1rem;
    color: white;
}
