:root {
    overflow: hidden;

    /*
        Custom application constant colors
    */
    --main-application-color: #333333;
    --red-color: #cd373f;
    --Grays-white: #f1f1f1;

    --blue-color: #03609B;
    --light-blue-color: #4e9cd5;
    --lighter-blue: #31BDF9;
    --avatar-background: #35383F;

    --bebas: Bebas Neue;
    --poppins: Poppins;

    background: white;
    color: white;
    height: 100vh;
}

.page-container {
    background: url('assets/logo.jpg') no-repeat center;
    background-size: cover;
    margin-top: 7rem;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    padding: 20px 20px 6rem 20px;
    color: var(--Grays-white);
    font-family: var(--poppins), sans-serif;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .page-container {
        margin-top: 8rem;
    }
}
